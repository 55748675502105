<template>
  <section id="add-card">
    <div class="card-container">
      <app-label>Card Number</app-label>
      <div id="card-number"></div>
      <div class="grid-x">
        <div class="cell auto">
          <app-label>Expiry</app-label>
          <div id="card-expiry"></div>
        </div>
        <div class="cell auto">
          <app-label>CVC</app-label>
          <div id="card-cvc"></div>
        </div>
      </div>
      <div id="card-error"></div>
      <div class="button">
        <app-button id="custom-button" @click="confirmSetupIntent" :loading="isLoading">Add card</app-button>
      </div>
    </div>
  </section>
</template>

<script>
import stripeApi from '@/services/api/stripe';

export default {
  name: 'add-card',
  props: {
    setAsDefault: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      isLoading: false,
      setupIntent: {},
      stripeElements: null,
    };
  },
  async mounted() {
    this.getSetupIntent();

    this.stripeElements = this.$stripe.elements();

    // style of stripe components (see https://stripe.com/docs/js/appendix/style)
    const style = {
      base: {
        color: '#fff',
        fontFamily: '"Inter", Inter, sans-serif',
        '::placeholder': {
          color: '#aaa0d4',
        },
      },
      invalid: {
        color: '#FF8A80',
      },
    };
    this.cardNumber = this.stripeElements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');
  },
  beforeDestroy() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  methods: {
    async getSetupIntent() {
      try {
        const response = await stripeApi.getSetupIntent();
        this.setupIntent = response.data;
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: 'Impossible to add a credit card at the moment. Please try again later.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    async confirmSetupIntent() {
      this.isLoading = true;

      const { error, setupIntent } = await this.$stripe.confirmCardSetup(this.setupIntent.secret, {
        payment_method: { card: this.stripeElements.getElement('cardNumber') },
      });
      if (error) {
        document.getElementById('card-error').innerHTML = error.message;
      } else if (setupIntent) {
        if (this.setAsDefault) {
          await this.setDefault(setupIntent.payment_method);
        }
        this.$emit('add-card');
        this.$notification.show({
          text: 'The card was added successfully !',
        });
      }

      this.isLoading = false;
    },
    async setDefault(paymentMethodId) {
      try {
        await stripeApi.setDefaultCard(paymentMethodId);
      } catch {
        // silencieux: elle sera ajouté si besoin par un webhook
      }
    },
  },
};
</script>
<style lang="sass">
  #add-card
    .card-container
      display: grid
      .grid-x
        gap: 24px
      .button
        display: flex
        justify-content: left
        #custom-button
          margin-bottom: 20px
          margin-top: 8px
          padding: 12px 16px
      #card-error
        color: $warning!important
  .StripeElement
    background-color: $primary70
    border-radius: 4px
    padding: 12px 16px
    margin-bottom: 24px
    margin-top: 7px
    width: 100%
    &--focus
      outline: 1px solid $primary
</style>
