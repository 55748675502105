<template>
  <section class="onboarding">
    <img class="background-image" src="@/assets/img/background-login.jpeg" />
    <div class="grid-x">
      <div class="cell auto">
        <div class="logo-header">
          <logo alt="doppio" />
        </div>
      </div>
      <div class="cell shrink cancel" @click="cancelOnBoarding">
        <cancel alt="cancel" />
      </div>
    </div>

    <div class="grid-x onboarding-container">
      <div class="cell auto illustration-container">
        <img src="@/assets/img/illu-onboarding.png" />
      </div>
      <!-- FORMULAIRE ETAPE 1 - INFO USER -->
      <div
        class="cell auto form-container"
        v-if="$route.params.step === 1 || $route.params.step === '1'"
      >
        <form v-if="user" class="form" ref="register" @submit.prevent="() => updateUser(2)">
          <div class="steps">
            <h5>1 / 3</h5>
          </div>
          <div>
            <h1>Welcome on Doppio!</h1>
          </div>
          <div>
            <p>To complete your profile, we need some information about you.</p>
          </div>
          <div>
            <app-label required>Firstname</app-label>
            <app-input autocomplete="firstname" required v-model="user.firstname" />
          </div>
          <div>
            <app-label required>Lastname</app-label>
            <app-input autocomplete="lastname" required v-model="user.lastname" />
          </div>
          <div>
            <app-label>Company</app-label>
            <app-input autocomplete="company" v-model="user.company" />
          </div>
          <div>
            <app-button class="next" type="submit" size="large">Next<right-arrow /></app-button>
          </div>
        </form>
      </div>
      <!-- FORMULAIRE ETAPE 2 - ADRESSE -->
      <div
        class="cell auto form-container"
        v-else-if="$route.params.step === 2 || $route.params.step === '2'"
      >
        <form v-if="user" class="form" ref="register" @submit.prevent="() => updateUser(3)">
          <div class="steps">
            <h5>2 / 3</h5>
          </div>
          <div>
            <h1>Your address</h1>
          </div>
          <div>
            <p>We also need your address.</p>
          </div>
          <div class="grid-x split-grid">
            <div class="cell auto">
              <app-label required>Address</app-label>
              <app-input autocomplete="line1" required v-model="user.address.line1" />
            </div>
          </div>
          <div class="grid-x split-grid">
            <div class="cell small-3">
              <app-label required>ZIP Code</app-label>
              <app-input
                autocomplete="postalCode"
                required
                v-model="user.address.postalCode"
              />
            </div>
            <div class="cell auto">
              <app-label required>City</app-label>
              <app-input autocomplete="city" required v-model="user.address.city" />
            </div>
          </div>
          <div>
            <app-label required>Country</app-label>
            <app-select
              v-if="countries && countries.length"
              :options="countries"
              required
              v-model="user.address.country"
            />
          </div>
          <div>
            <app-button class="next" type="submit" size="large">Next<right-arrow /></app-button>
          </div>
        </form>
      </div>
      <!-- FORMULAIRE ETAPE 3 - CARTES -->
      <div
        class="cell auto form-container"
        v-else-if="$route.params.step === 3 || $route.params.step === '3'"
      >
        <div class="form">
          <div class="steps">
            <h5>3 / 3</h5>
          </div>
          <div>
            <h1>Optional: billing information</h1>
          </div>
          <div>
            <p>
              Please note that this step is optional and only applies to users who want to subscribe
              to a paid plan.
            </p>
          </div>
          <div class="card">
            <add-card @add-card="addCard" setAsDefault />
          </div>
          <div class="later">
            <router-link :to="{ name: 'home' }">Skip this step →</router-link>
          </div>
        </div>
      </div>
      <div v-else class="cell auto"></div>
    </div>
  </section>
</template>

<script>
import userApi from '@/services/api/user';
import utils from '@/services/utils/utils';
import countriesData from '@/services/data/countries';

import AddCard from '@/views/cards/AddCard.vue';

import logo from '@/assets/img/logo.svg?inline';
import rightArrow from '@/assets/img/right-arrow.svg?inline';
import cancel from '@/assets/img/cancel.svg?inline';

export default {
  name: 'onboarding',
  components: {
    logo,
    'right-arrow': rightArrow,
    AddCard,
    cancel,
  },
  data() {
    return {
      user: null,
      countriesData,
      countries: utils.enumToOptions(countriesData),
    };
  },
  async mounted() {
    try {
      this.user = await userApi.getMe();
      if (this.user.address === null) {
        this.user.address = {
          city: '',
          country: null,
          line1: '',
          line2: '',
          postalCode: '',
          state: '',
        };
      }
    } catch (error) {
      this.$message.show({
        title: 'Error',
        text: 'Impossible to retrieve your information.',
        confirmText: 'Ok',
        hasCancel: false,
      });
      throw error;
    }
  },
  destroyed() {
    this.$emit('updateCardInformation', true);
    this.launchWelcome();
  },
  methods: {
    async updateUser(step) {
      if (this.$refs.register.checkValidity()) {
        const user = { ...this.user };
        try {
          await userApi.update(user);
          this.$notification.show({
            text: 'Your information is succesfully saved.',
          });

          this.$router.push({ name: 'onboarding', params: { step } });
        } catch (error) {
          this.$message.show({
            title: 'Error',
            text: 'Impossible to update your information',
            confirmText: 'Ok',
            hasCancel: false,
          });

          throw error;
        }
      } else {
        this.$refs.register.reportValidity();
      }
    },
    addCard() {
      this.$router.push({ name: 'home' });
    },
    cancelOnBoarding() {
      this.$router.push({ name: 'home' });
    },
    launchWelcome() {
      setTimeout(
        this.$message.show({
          title: '🎉 Welcome on Doppio!',
          text:
            'You can retrieve your 🔑 API key from the "API Key" menu and generate your first PDF or screenshot by following the "Getting Started" guide in the Documentation section. 🗒 Happy PDF-ing!',
          confirmText: 'Ok',
          hasCancel: false,
        }),
        300,
      );
    },
  },
};
</script>

<style lang="sass">
.onboarding
  @include page
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  padding-top: 20px
  z-index: 0
  overflow: hidden
  background-color: $primary100
  .grid-x
    width: 100%
  .onboarding-container
    min-height: calc(100vh - 170px)
    justify-content: center
  h1
    color: $white
    text-align: left
  .google-link button
    background-color: white
    color: black
    display: flex
    justify-content: center
    align-items: center
    svg
      width: 16px
      margin-right: 10px
  .background-image
    position: absolute
    z-index: -1
    top: 0
    left: 0
    width: 50%
    height: 100%
  .link
    @include link
    color: white
    text-decoration: underline
    padding-top: 5px
    display: block
  .logo-header
    svg
      width: 110px
  .illustration-container
    display: flex
    align-items: center
    justify-content: center
    svg
      width: 70%
      margin-top: 50px
  .form-container
    display: flex
    align-items: center
    justify-content: center
  .form
    width: 100%
    margin: 0 70px
    max-width: 400px
    > div
      padding: 1rem 0 0
  .next
    display: flex
    align-items: center
    justify-content: center
    svg
      width: 12px
      margin-left: 5px
  .steps
    background-color: $primary
    padding: 8px 12px!important
    width: fit-content
    border-radius: 25px
    h5
      color: white
  .split-grid
    .cell.small-3
      margin-right: 10px
  .cancel
    cursor: pointer
    svg
      width: 24px
  .card
    margin: 10px 0 0
  .later
    a
      text-decoration: underline
</style>
